import React from 'react';
import { track } from '@/shared/analytics';

import { IHome } from '@/interfaces/home';
import { TruncateText } from '@/lib/TruncateText';
import { currencyFormat } from '@/lib/CurrencyFormat';
import { IStaticLocale } from '@/interfaces/staticlocale';
import { IFinalProductProps } from '@/interfaces/products';
import { getFirstProductImage } from '@/lib/GetFirstProductImage';

import './style.scss';

type Props = {
  product: IFinalProductProps;
  home: IHome;
  locale: IStaticLocale;
  hasAction: boolean;
};

const ProductCard = (props: Props) => {
  const { product, home, locale, hasAction } = props;
  const { name, internalName, shortDescription, images, currentTariff } =
    product;

  return (
    <a
      href={product.currentPath}
      role="region"
      aria-label="produt"
      className="product-container"
      onClick={() =>
        track('productListProductClick', {
          template: 'virgoA',
          productId: product.id,
          productName: product.name,
        })
      }
    >
      <div className="product-image">
        <img
          src={getFirstProductImage({ images, styleName: 'thumbnail' })}
          alt={internalName}
        />
      </div>

      <div className="product-info">
        <h2>{TruncateText(name, 38)}</h2>
        <p className="product-info-description">
          {TruncateText(shortDescription)}
        </p>

        <div className="product-price-container">
          {currentTariff?.oldPriceCents && (
            <p className="product-old-price">
              {locale.product_detail.from}{' '}
              <span>R$ {currencyFormat(currentTariff.oldPriceCents)}</span>{' '}
              {locale.product_detail.to}
            </p>
          )}
          <p className="product-price">
            R$ {currencyFormat(currentTariff?.priceCents!)}
          </p>
        </div>
        <p className="product-under-price-note">
          {home.productList.homeProductListUnderPrice}
        </p>
      </div>
      {hasAction ? (
        <button className="product-cta">
          {home.productList.homeProductListCta}
        </button>
      ) : null}
    </a>
  );
};

export default ProductCard;

import React from 'react';

import { classNames } from '@/shared/helpers';
import './style.scss';

type Props = {
  content: {
    title: string;
    text: string;
  }[];
  title: string;
  subTitle: string;
  type: 'default' | 'alternative';
};

const HowItWorks = (props: Props) => {
  const { type, content, title, subTitle } = props;

  return (
    <div
      role="region"
      className={classNames(
        'how-it-works-container',
        type === 'alternative' ? 'alternative-container-style' : ''
      )}
    >
      <div className="how-it-works-info">
        <h2
          dangerouslySetInnerHTML={{
            __html: title,
          }}
        />
        <p dangerouslySetInnerHTML={{ __html: subTitle }} />
      </div>

      {content.length > 0 ? (
        <ul className="how-it-works-list">
          {content.map((step, index) => (
            <li
              key={`title-${index}-${step.title}`}
              className="how-it-works-list-item"
            >
              <h2>{content.length < 9 ? `0${index + 1}` : index}</h2>
              <h3>{step.title}</h3>
              <p>{step.text}</p>
            </li>
          ))}
        </ul>
      ) : null}
    </div>
  );
};

export default HowItWorks;

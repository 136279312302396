interface FirstProductImageProps {
    images: Array<any>
    styleName: string;
}

export const getFirstProductImage =({images, styleName}: FirstProductImageProps) => {
    if(!images || images.length === 0)
        return '';

    const img = images.find(img => img.thumbnail) || images[0];
    
    for(let st of img.styles){
        if(st.style == styleName){
            return st.url;
        }
    }

    return '';
};
import { PRODUCT_CARDS, TESTIMONY_CARDS } from "@/lib/CardsType"

export const DecideHomeDisplay = (displayType: string) => {

    const display: HomeDisplay = {

        default: {
            type: displayType,
            showHighLightText: true,
            productsCard: PRODUCT_CARDS.WITH_ACTION,
            testimoniesCard: TESTIMONY_CARDS.DEFAULT
        },

        alternative: {
            type: displayType,
            showHighLightText: false,
            productsCard: PRODUCT_CARDS.DEFAULT,
            testimoniesCard: TESTIMONY_CARDS.ALTERNATIVE
        }
    }

    return display[displayType];
}

export const getOtherGlideOptions = (displayType: string) => {

    const glideOptions: GlideOptions = {

        'default': {
            perView: 3,
            
            breakpoints: {
                1581: {
                    perView: 2,
                },
                800: {
                    perView: 1,
                }
            }
        },
    
        'alternative': {
            perView: 4,
            breakpoints: {
                1500: {
                    perView: 3,
                },

                1150: {
                    perView: 2
                },

                780: {
                    perView: 1
                }
            }
        }
    }

    return glideOptions[displayType]
}

interface HomeDisplay{
    
    [key: string]: {
        type: string;
        showHighLightText: boolean;
        productsCard: string;
        testimoniesCard: string;
    },
}

interface GlideOptions{

    [key: string]: {
        perView: number,
        breakpoints?: {
            [key: number]: {
                perView: number;
            }
        }
    }
}
export const TruncateText = (text: string | undefined, charsLen?: number) => {
    const DEFAULT_CHAR_LEN_FOR_TRUNCATE = 139

    if(text){
        charsLen = charsLen ?? DEFAULT_CHAR_LEN_FOR_TRUNCATE;
        const suffix = '...';
        return text.length > charsLen ? `${text.slice(0, charsLen - suffix.length)}${suffix}` : text;
    }
    
    return ''
}